/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('RegInputController',['$scope', '$rootScope', 'DTColumnBuilder', '$filter', 'Language', 'RegFactory', '$stateParams', 'HeaderService', '$state', 'TableFilterFactory', 'GlobalDataFactory', function ($scope, $rootScope, DTColumnBuilder, $filter, Language, RegFactory, $stateParams, HeaderService, $state, TableFilterFactory, GlobalDataFactory) {

        //region General

        HeaderService.onChangeState($scope, function (message) {
            if(_.contains(['annexa.reg.input.entries', 'annexa.reg.input.diligences', 'annexa.reg.input.diligences.rejected'], message.state.name)) {
                $rootScope.headButtons = [];
                $rootScope.subHeadButtons = [ new HeadButtonSearch('#tableFilter') ];
                $rootScope.subHeadTabs = [
                    { state: 'annexa.reg.input.entries', type: 'breadDivButton', name: 'global.literals.inputs', permissions: ['view_input_register', 'view_all_input_register'] },
                    { state: 'annexa.reg.input.diligences', type: 'breadDivButton active', name: 'global.literals.titleDiligencies', permissions: ['diligence_input_register','view_all_diligences'] },
                    { state: 'annexa.reg.input.diligences.rejected', type: 'breadDivButton', name: 'global.literals.titleDiligenciesRejected', permissions: ['view_tab_rejected_diligences'] }
                ];
            }

            if(message.state.name == 'annexa.reg.input.entries') {
                $rootScope.headButtons = [ new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableInput','fa-plus', 'global.reg.input.new', undefined, 'newInput').setPermissions('new_input_entry_register') ];
                $rootScope.subHeadTabs[0].type = 'breadDivButton active';
                $rootScope.subHeadTabs[1].type = 'breadDivButton';
                $rootScope.subHeadTabs[2].type = 'breadDivButton';
            } else if (message.state.name == 'annexa.reg.input.diligences.rejected') {
                $rootScope.subHeadTabs[0].type = 'breadDivButton';
                $rootScope.subHeadTabs[1].type = 'breadDivButton';
                $rootScope.subHeadTabs[2].type = 'breadDivButton active';
            }
        });

        HeaderService.changeState($state.current);

        $scope.filterData = {
            pending: '',
            loggedUser: $rootScope.LoggedUser.id
        };

        //endregion

        //region Filter
        
        $scope.filterColumns = [
            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number' },
            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true },
            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true },
            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract' },
            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true },
            { id: 'dataPres', type: 'dateRange', order: 7, label: 'global.literals.presFrom', callAux: true },
            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'entryClassification', type: 'select', order: 9, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'representant', type: 'text', order: 11, label: 'global.literals.origin', callAux: true },
            //{ id: 'documentNumber', type: 'text', order: 12, label: 'global.literals.documentNumber', callAux: true },
            { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'diligenceProfile', type: 'select-multiple', order: 14, label: 'global.literals.diligenceprofile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true }
        ]

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filterColumns.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn() });
        }

        //endregion

        //region Table

        $scope.getFilterCall = function (filter) {
            var filterCall = TableFilterFactory.getFilterCall(filter);

            filterCall.entryType = 'INPUT';

            if($scope.filterData) {
                switch ($scope.filterData.pending) {
                    case 'true':
                        filterCall.pending = true;
                        break;
                    case 'false':
                        filterCall.pending = false;
                        break;
                }
            }

            if($state.current.name == 'annexa.reg.input.diligences' || $state.current.name == 'annexa.reg.input.diligences.rejected') {
                filterCall = { registerEntry: filterCall };
            }

            return filterCall;
        }

        $scope.getFilterCallAux = function (filter) {
            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);

            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            filterCallAux.langColumn = Language.getActiveColumn();

            if(filterCallAux && filterCallAux.diligenceProfile && filterCallAux.diligenceProfile.length > 0){
            	var profiles = [];
            	_.forEach(filterCallAux.diligenceProfile, function(profile){
            		if(profile.id){
            			profiles.push({id:profile.id});
            		}
            	});
            	filterCallAux.diligenceProfile = profiles;
            }
            if($state.current.name == 'annexa.reg.input.diligences') {
                filterCallAux.diligenceWindow = true;
            }

            return filterCallAux;
        }

        $scope.datatableSettings = $rootScope.app.configuration.datatables_settings;
/*
        $scope.tableOrderProperties = $state.current.name == 'annexa.reg.input.diligences' ?
            { sortName: 'registerDate', sort: [[1,'desc']] } :
            { sortName: 'createdDate', sort: [[0,'desc']] };
*/
        $scope.headerPendingColumn = function() {
            var content = '';

            content += '<div class="btn-group dropdown">';
            content += '    <button class="btn btn-sm white dropdown-toggle  p-l-0" data-toggle="dropdown">';
            content += '        <span class="fa-stack">';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\' | translate}}</span>';
            content += '            <i ng-show="pendingShow(\'all\')" class="fa fa-minus fa-stack-1x"></i>';
            content += '            <i ng-show="pendingShow(\'pending\')" class="fa fa-clock-o text-amber text-lg"></i>';
            content += '        </span>';
            content += '    </button>';
            content += '    <div class="dropdown-menu">';
            content += '        <a class="dropdown-item" ng-click="pendingFilter(\'all\')">';
            content += '            <span><i class="fa  fa-minus"></i></span> {{\'global.literals.all\' | translate}}';
            content += '        </a>';
            content += '        <a class="dropdown-item" ng-click="pendingFilter(\'pending\')">';
            content += '            <span><i class="fa fa-clock-o text-amber text-lg"></i></span> {{\'global.literals.pending\' | translate}}';
            content += '        </a>';
            content += '    </div>';
            content += '</div>';

            return content;
        };

        $scope.pendingShow = function (type) {
            if((type == 'all' && $scope.filterData.pending == '') || (type == 'pending' && $scope.filterData.pending == 'true')) {
                return true;
            }

            return false;
        }

        $scope.pendingFilter = function (type) {
            switch (type) {
                case 'all':
                    $scope.filterData.pending = '';
                    break;
                case 'pending':
                    $scope.filterData.pending = 'true';
                    break;
            }

            $scope.tableDefinition.reloadInternalData(true);
        }

        $scope.pendingColumnRender = function(data, type, full, meta) {
            var content = '<div>';

            if(data){
                content += '<i class="fa-clock-o text-amber text-lg" title="' + $filter('translate')('global.reg.datatable.pending') + '"></i>';
            }

            content += '</div>';

            return content;
        };

        //endregion

    }])
    .controller('RegInputEntriesController',['$scope', '$rootScope', '$filter', '$state', 'AnnexaModalFactory', 'RegModals', 'RegFactory', 'ErrorFactory','$stateParams', 'RouteStateFactory', 'Language', 'DialogsFactory', 'GlobalDataFactory', function($scope, $rootScope, $filter, $state, AnnexaModalFactory, RegModals, RegFactory, ErrorFactory, $stateParams, RouteStateFactory, Language, DialogsFactory, GlobalDataFactory) {

        //region Filter
    	
    	// ORDENAR EL LLISTAT DE REGISTRE //
    	$scope.tableOrderProperties = { sortName: 'createdDate', sort: [[0,'desc']] };

        $scope.filter = angular.copy($scope.filterColumns);

        var adiElements = [
            { id: -1, language1: $filter('translate')('global.literals.withoutDiligences'), language2: $filter('translate')('global.literals.withoutDiligences'), language3: $filter('translate')('global.literals.withoutDiligences') }
        ];

        $scope.filter.push(
            { id: 'diligenceType', type: 'select', order:  10, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: RegFactory.registerEntryDiligenceTypes, addAll: true, addItems: angular.copy(adiElements),nameProperty: Language.getActiveColumn(), callAux: true }
        );

        var routeState = RouteStateFactory.getRouteState($state.current);

        if(routeState ) {
            $scope.filter = routeState.state;
        }

        //endregion

        //region Table

        var getFilterCall = function() {
            return $scope.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function() {
            return $scope.getFilterCallAux($scope.filter);
        }

        var renderDiligenceColumn = function(data, type, full, meta) {
            var content = '';

            // Només s'han de mostrar les diligències actives
            var activeDiligences = $linq(data).where("x => x.active == true").toArray();
            var rejectedDiligences = $linq(activeDiligences).count("x => x.diligenceState == 'REJECT'");

            content += '<div class="text-nowrap">';

            if(rejectedDiligences == activeDiligences.length) {
                content += '<i class="fa fa-warning text-danger" aria-hidden="true"></i> ';
            } else if(rejectedDiligences != activeDiligences.length && rejectedDiligences != 0) {
                content += '<i class="fa fa-warning text-warn" aria-hidden="true"></i>';
            }

            content += '<a href="" class="text-primary" ng-click="getDiligencies(' + full.id + ')">';
            content += '    <span>' + activeDiligences.length + ' ' + $filter('translate')('global.literals.titleDiligencies') + '</span>';
            content += '</a>';

            content += '</div>';

            return content;
        };

        var columns = [
            { id: 'entryNumber', width: '110px', title: $filter('translate')('global.literals.number') },
            { id: 'registerDate', width: '110px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
            { id: 'presentationDate', width: '90px', column: new DateColumn($filter,'global.literals.presFrom') },
            { id: 'registerEntryChannel.'+ Language.getActiveColumn(), width: '60px', title: $filter('translate')('global.literals.channel') },
            { id: 'extract', title: $filter('translate')('global.literals.extract'), sortable: false },
            { id: 'thirds', width: '200px', column: new PrincipalThirdColumn($filter,'global.literals.principalThirdRegisterEntryInput'), sortable: false },
            { id: 'entryDiligences', width: '110px', title: $filter('translate')('global.literals.titleDiligencies'), render: renderDiligenceColumn, sortable: false},
            { id: 'actions', columnName: 'id', width: '100px', className: 'text-center', sortable: false, column: new ActionsColumn(
                    $filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','annexa.reg.input.entries.edit({ input: \'[data]\'})','fa-eye', true),
                    [ new ActionButton('global.reg.literals.generateSupportingDocument','createSuportingDocument([data])','', false) ]
                )}
        ];

        if($rootScope.app.configuration.reg_pending.value) {
            columns.splice(columns.length - 1, 0, { id: 'pending', width: '20px', title: $scope.headerPendingColumn(), render: $scope.pendingColumnRender, sortable: false })
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.unshift({ id: 'registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            $scope.tableOrderProperties.sort.index += 1;
        }

        columns = getDatatableColumnsSettings($scope.datatableSettings, 'datatable_register_entry_input', columns);

        var orderProperties = getDatatableGetColumnOrderProperties($scope.datatableSettings, 'datatable_register_entry_input', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableRegInputEntries',
            origin: 'reg',
            objectType: 'RegisterEntry',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy(columns),
            containerScope: $scope,
            useTableObjects: true
        }
        //endregion

        //region Mètodes

        $scope.newInput = function(){
            $state.transitionTo('annexa.reg.input.entries.new');
        };

        $scope.getDiligencies =  function(id){
            var modal = RegModals.diligences;
            modal.data = { diligences: $linq($scope.tableObjects[id].entryDiligences).where("x => x.active == true").toArray(), langColumn: Language.getActiveColumn() };
            AnnexaModalFactory.showModal('modalDiligences', modal);
        }

        $scope.createSuportingDocument = function (id) {
            $rootScope.loading(true);

            RegFactory.createSupportingDocument(id, 'INPUT', 'FILE')
                .then(function(data) {
                    if(window.navigator.msSaveOrOpenBlob) {
                        try {
                            window.navigator.msSaveOrOpenBlob(data, "pdf.pdf");
                        } catch(e) {
                            console.error(e.message);
                        }
                    } else {
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        data.dispatchEvent(clickEvent);
                    }
                    $rootScope.loading(false);
            }).catch(function(error) {
                $rootScope.loading(false);

                DialogsFactory.error(ErrorFactory.getErrorMessage('register', 'supportingdocument', error && error.data ? error.data : ''));
            });
        };

        //endregion

    }])
    .controller('RegInputEntriesNewController',['$scope', '$rootScope', 'HeaderService', '$state', function($scope, $rootScope, HeaderService, $state) {

        //region General

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.reg.input.entries.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#new-registerentry','fa-floppy-o','global.literals.save',undefined,'saveRegistry'),
                    new HeadButton('btn grey-500 text-white breadDivButton ml-sm-pt', undefined, '#new-registerentry',undefined,'global.literals.cancel',undefined,'cancelRegistry')
                ];
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegInputEntriesEditController',['$scope', '$rootScope', 'HeaderService', '$state', 'RegFactory', 'PermPermissionStore', function($scope, $rootScope, HeaderService, $state, RegFactory, PermPermissionStore) {

        // region General

        $scope.registerEntry = angular.copy(RegFactory.regEntry);

        HeaderService.onChangeState($scope, function (message) {
            if (message.state.name == 'annexa.reg.input.entries.edit') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', undefined, 'global.reg.literals.generateSupportingDocument', undefined, 'createSuportingDocument'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditRegisterEntry').setPermissions('admin_audit')
                ];
                
                if ($rootScope.app.configuration.show_sicres_button && $rootScope.app.configuration.show_sicres_button.value) {
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-registerentry', undefined, 'global.reg.literals.generateDocumentSicres', undefined, 'generateDocumentSicres'));
                }
            }
        });

        HeaderService.changeState($state.current);

        //endregion

    }])
    .controller('RegInputDiligencesController',['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'RegFactory', 'RouteStateFactory', 'Language', 'HelperService', 'GlobalDataFactory', function($scope, $rootScope, $filter, $state, $stateParams, RegFactory, RouteStateFactory, Language, HelperService, GlobalDataFactory) {

        //region Filter
    	
    	// ORDENAR LES DILIGENCIES //
    	$scope.tableOrderProperties = { sortName: 'registerDate', sort: [[1,'desc']] };
    	
        $scope.filter = angular.copy($scope.filterColumns);

        var adiElements = [
            { id: -1, language1: $filter('translate')('global.literals.withoutDiligences'), language2: $filter('translate')('global.literals.withoutDiligences'), language3: $filter('translate')('global.literals.withoutDiligences') }
        ];

        $scope.filter.push(
            { id: 'diligenceType', type: 'select', order: 9, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: RegFactory.registerEntryDiligenceTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: $stateParams.diligenceType ? $stateParams.diligenceType : undefined }
        );

        var registerEntryDiligenceStates = [
            { id: 'ACCEPT', name: 'global.reg.diligenceState.ACCEPT' },
            { id: 'REJECT', name: 'global.literals.REJECT' },
            { id: 'READ', name: 'global.reg.diligenceState.READ' },
            { id: 'PENDING_ACCEPT', name: 'global.reg.diligenceState.PENDING_ACCEPT' },
            { id: 'PENDING_RECEPT', name: 'global.reg.diligenceState.PENDING_RECEPT' },
            { id: 'FINISHED', name: 'global.reg.diligenceState.FINISHED' }
        ];

        $scope.filter.push(
            { id: 'diligenceStatus', type: 'select-multiple', order: 14, label: 'global.literals.state', dataType: 'LOCAL', data: registerEntryDiligenceStates, addAll: true, nameProperty:'name', callAux: true, preFilter:(($stateParams.diligenceStatus)?(($stateParams.diligenceStatus == 'PENDING_RECEPT')?[{ id: 'PENDING_RECEPT' }]:[{ id: 'PENDING_ACCEPT' }]):[{ id: 'PENDING_ACCEPT' }, { id: 'PENDING_RECEPT' }, { id: 'ACCEPT' }]) }
        );

        var indexDiligenceProfileColumnFilter = $linq($scope.filter).indexOf("x => x.id == 'diligenceProfile'");
        //Mirem si existeix el permís 'view_all_diligences' dins dels rols de l'usuari
        var actionPermissionViewAllDiligences = $linq($rootScope.LoggedUser.userRoles).selectMany("x => x.role.actionPermissions").where("x => x.actionPermission.keyId == 'view_all_diligences'").toArray();
        
        //Si no existeix el permís llavors l'anem a buscar dins dels perfils de l'usuari
        if (actionPermissionViewAllDiligences.length == 0) {
        	actionPermissionViewAllDiligences = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").selectMany("x => x.roles").selectMany("x => x.actionPermissions").where("x => x.actionPermission.keyId == 'view_all_diligences'").toArray();
        }
        
        //En cas que no existeix aquest permís es carregaran al filtre els perfils de l'usuari
        if(indexDiligenceProfileColumnFilter != -1 && actionPermissionViewAllDiligences.length == 0) {
            $scope.filter[indexDiligenceProfileColumnFilter].data = new SortedArray($linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray(), Language.getActiveColumn()).sort();
        }

        $scope.filter.push(
            { id: 'withoutDossier', type: 'checkbox', order: 15, label: 'global.literals.withoutDossier', callAux: true}
        );

        var routeState = RouteStateFactory.getRouteState($state.current);

        if($stateParams.diligenceType){
        	RouteStateFactory.addRouteState($state.current, $scope.filter);
        }else if(routeState && !$stateParams.diligenceType) {
            $scope.filter = routeState.state;
        }

        //endregion

        //region Table

        var getFilterCall = function() {
            return $scope.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function() {
            return $scope.getFilterCallAux($scope.filter);
        }

        var renderDiligenceRemaingDaysColumn = function(data, type, full, meta) {
            var content = '';

            if(data && (full.diligenceState == 'PENDING_ACCEPT' || full.diligenceState == 'PENDING_RECEPT')) {
                var days = HelperService.getWorkingDays(new Date(data), new Date(), $rootScope.LoggedUser.entity.holidays);

                content += '<span class="label ' + HelperService.getWorkingDaysDiligenceClass(days) + '">' + days + '</span>';
            }

            return content;
        }

        var renderCommentColumn = function(data, type, full, meta) {
            return '<table-data-comment-column row-object="tableObjects[' + full.id + ']" row-property="' + meta.settings.aoColumns[meta.col].mData + '" comment-property="comments" user-property="createdUser" can-create-comment="true" type="RegisterEntryDiligence"></table-data-comment-column>'
        }

        var columns = [
            { id: 'createdDate', width: '50px', title: $filter('translate')('global.literals.days'), render: renderDiligenceRemaingDaysColumn },
            { id: 'registerEntry.entryNumber', width: '110px', title: $filter('translate')('global.literals.number') },
            { id: 'registerEntry.registerDate', width: '110px', column: new DateTimeColumn($filter, 'global.reg.datatable.registerDate') },
            { id: 'registerEntry.extract', title: $filter('translate')('global.literals.extract'), sortable: false },
            { id: 'registerEntry.thirds', width: '200px', column: new PrincipalThirdColumn($filter, 'global.literals.principalThirdRegisterEntryInput'), sortable: false },
            { id: 'diligenceType.' + Language.getActiveColumn(), width: '150px', column: new EllipsableColumn($filter, 'global.literals.diligenceType', 30) },
            { id: 'diligenceProfile.' + Language.getActiveColumn(), width: '90px', column: new EllipsableColumn($filter, 'global.literals.profile', 30) },
            { id: 'diligenceState', width: '150px', column: new PartialLanguageColumn($filter, 'global.literals.state', 'global.reg.diligenceState.') },
            { id: 'registerEntryDiligenceComments', width: '100px', title: $filter('translate')('global.literals.comments'), render: renderCommentColumn, sortable: false },
            { id: 'responsibleUser', width: '100px', column: new UserColumn($filter, 'global.literals.responsibleUser', false) },
            { id: 'actions', columnName: 'registerEntry.id', width: '100px', className: 'text-center', sortable: false, column: new ActionsColumn(
                    $filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','annexa.reg.input.diligences.edit({ input: \'[data]\', diligence:\'[data2]\'})','fa-eye', true),
                    undefined,
                    'id'
                )}
        ];

        if($rootScope.app.configuration.reg_pending.value) {
            columns.splice(columns.length - 1, 0, { id: 'registerEntry.pending', width: '20px', title: $scope.headerPendingColumn(), render: $scope.pendingColumnRender, sortable: false });
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.splice(1, 0, { id: 'registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            if ($scope.tableOrderProperties.sort.index >= 1) {
            	$scope.tableOrderProperties.sort.index += 1;
            }
        }

        columns = getDatatableColumnsSettings($scope.datatableSettings, 'datatable_register_entry_input_diligences', columns);

        var orderProperties = getDatatableGetColumnOrderProperties($scope.datatableSettings, 'datatable_register_entry_input_diligences', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableRegInputDiligence',
            origin: 'reg',
            objectType: 'RegisterEntryDiligenceView',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
            useTableObjects: true
        }

        //endregion

        //region Mètodes

        $scope.$on('addedComment', function(evert) {
            $scope.tableDefinition.reloadInternalData(false);
        })

        //endregion

    }])
    .controller('RegInputDiligencesRejectedController',['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'RegFactory', 'RouteStateFactory', 'Language', 'HelperService', 'GlobalDataFactory', function($scope, $rootScope, $filter, $state, $stateParams, RegFactory, RouteStateFactory, Language, HelperService, GlobalDataFactory) {

        //region Filter    
    	$scope.filterColumnsDiligencesRejected = [
    		{ id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number' },
            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true },
            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true },
            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract' },
            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true },
            { id: 'dataPres', type: 'dateRange', order: 7, label: 'global.literals.presFrom', callAux: true },
            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'entryClassification', type: 'select', order: 10, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'representant', type: 'text', order: 11, label: 'global.literals.origin', callAux: true },
            { id: 'section', type: 'select-tree', order: 12, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() }
        ];
    	
    	if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            $scope.filterColumnsDiligencesRejected.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn() });
        }
    	
    	// ORDENAR LES DILIGENCIES //
    	$scope.tableOrderProperties = { sortName: 'registerDate', sort: [[1,'desc']] };
    	
        $scope.filter = angular.copy($scope.filterColumnsDiligencesRejected);

        $scope.filter.push(
            { id: 'diligenceType', type: 'select', order: 9, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: RegFactory.registerEntryDiligenceTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: $stateParams.diligenceType ? $stateParams.diligenceType : undefined }
        );

        $scope.filter.push(
            { id: 'withoutDossier', type: 'checkbox', order: 13, label: 'global.literals.withoutDossier', callAux: true}
        );

        //endregion

        //region Table

        var getFilterCall = function() {
            return $scope.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function() {
        	var filterCallAux = $scope.getFilterCallAux($scope.filter);
        	filterCallAux.tabType='RegInputDiligencesRejected';
            return filterCallAux;
        }

        var renderDiligenceRemaingDaysColumn = function(data, type, full, meta) {
            var content = '';

            if(data && (full.diligenceState == 'PENDING_ACCEPT' || full.diligenceState == 'PENDING_RECEPT')) {
                var days = HelperService.getWorkingDays(new Date(data), new Date(), $rootScope.LoggedUser.entity.holidays);

                content += '<span class="label ' + HelperService.getWorkingDaysDiligenceClass(days) + '">' + days + '</span>';
            }

            return content;
        }

        var renderCommentColumn = function(data, type, full, meta) {
            return '<table-data-comment-column row-object="tableObjects[' + full.id + ']" row-property="' + meta.settings.aoColumns[meta.col].mData + '" comment-property="comments" user-property="createdUser" can-create-comment="true" type="RegisterEntryDiligence"></table-data-comment-column>'
        }

        var columns = [
            { id: 'createdDate', width: '50px', title: $filter('translate')('global.literals.days'), render: renderDiligenceRemaingDaysColumn },
            { id: 'registerEntry.entryNumber', width: '110px', title: $filter('translate')('global.literals.number') },
            { id: 'registerEntry.registerDate', width: '110px', column: new DateTimeColumn($filter, 'global.reg.datatable.registerDate') },
            { id: 'registerEntry.extract', title: $filter('translate')('global.literals.extract'), sortable: false },
            { id: 'registerEntry.thirds', width: '200px', column: new PrincipalThirdColumn($filter, 'global.literals.principalThirdRegisterEntryInput'), sortable: false },
            { id: 'diligenceType.' + Language.getActiveColumn(), width: '150px', column: new EllipsableColumn($filter, 'global.literals.diligenceType', 30) },
            { id: 'diligenceProfile.' + Language.getActiveColumn(), width: '90px', column: new EllipsableColumn($filter, 'global.literals.profile', 30) },
            { id: 'diligenceState', width: '150px', column: new PartialLanguageColumn($filter, 'global.literals.state', 'global.reg.diligenceState.') },
            { id: 'registerEntryDiligenceComments', width: '100px', title: $filter('translate')('global.literals.comments'), render: renderCommentColumn, sortable: false },
            { id: 'responsibleUser', width: '100px', column: new UserColumn($filter, 'global.literals.responsibleUser', false) },
            { id: 'actions', columnName: 'registerEntry.id', width: '100px', className: 'text-center', sortable: false, column: new ActionsColumn(
                    $filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see','annexa.reg.input.diligences.edit({ input: \'[data]\', diligence:\'[data2]\'})','fa-eye', true),
                    undefined,
                    'id'
                )}
        ];

        if($rootScope.app.configuration.reg_pending.value) {
            columns.splice(columns.length - 1, 0, { id: 'registerEntry.pending', width: '20px', title: $scope.headerPendingColumn(), render: $scope.pendingColumnRender, sortable: false });
        }

        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
            columns.splice(1, 0, { id: 'registerEntry.registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
            if ($scope.tableOrderProperties.sort.index >= 1) {
            	$scope.tableOrderProperties.sort.index += 1;
            }
        }

        columns = getDatatableColumnsSettings($scope.datatableSettings, 'datatable_register_entry_input_diligences', columns);

        var orderProperties = getDatatableGetColumnOrderProperties($scope.datatableSettings, 'datatable_register_entry_input_diligences', columns);

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index >= 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.tableDefinition = {
            id: 'tableRegInputDiligenceRejected',
            origin: 'reg',
            objectType: 'RegisterEntryDiligenceView',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
            useTableObjects: true
        }

        //endregion

        //region Mètodes

        $scope.$on('addedComment', function(evert) {
            $scope.tableDefinition.reloadInternalData(false);
        })

        //endregion

    }])    
    .controller('RegInputDiligencesEditController',['$q', '$scope', '$rootScope', '$filter', 'HeaderService', '$state', '$stateParams', 'RegFactory', 'RestService', 'RegModals', 'BoxFactory', 'AnnexaFormlyFactory', 'DTColumnBuilder', 'AnnexaModalFactory', 'AnnexaEntityFactory', 'AnnexaPermissionsFactory', 'PermPermissionStore', 'ErrorFactory', 'dialogs', 'CommonService', 'Language', function($q, $scope, $rootScope, $filter, HeaderService, $state, $stateParams, RegFactory, RestService, RegModals, BoxFactory, AnnexaFormlyFactory, DTColumnBuilder, AnnexaModalFactory, AnnexaEntityFactory, AnnexaPermissionsFactory, PermPermissionStore, ErrorFactory, dialogs, CommonService, Language) {

        // region General

        $scope.registerEntry = angular.copy(RegFactory.regEntry);
        $scope.diligenceId = $stateParams.diligence;

        var updateHeaderButtons = function() {
            HeaderService.onChangeState($scope, function (message) {
                var diligence = undefined;

                if ($scope.registerEntry && $scope.diligenceId) {
                    var indexDiligence = $linq($scope.registerEntry.entryDiligences).indexOf("x => x.id == " + $scope.diligenceId);

                    if (indexDiligence != -1) {
                        diligence = $scope.registerEntry.entryDiligences[indexDiligence];
                    }
                }

                if (message.state.name == 'annexa.reg.input.diligences.edit' && diligence) {
                    $rootScope.subHeadButtons = [];

                    //region Diligence Buttons

                    var diligenceActions = [];

                    var acceptDiligenceButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa fa-check', 'global.literals.accept', undefined, 'acceptDiligence')
                        .setPermissions(['edit_input_entry_register', 'diligence_input_register', 'view_all_diligences']);
                    var rejectDiligenceButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa fa-minus-circle', 'global.literals.reject', undefined, 'rejectDiligence')
                        .setPermissions(['edit_input_entry_register', 'diligence_input_register', 'view_all_diligences']);
                    var sendToDiligenceButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa fa-mail-forward', 'global.literals.sendto', undefined, 'sendToDiligence')
                        .setPermissions(['edit_input_entry_register', 'diligence_input_register', 'view_all_diligences']);
                    var finishDiligenceButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa fa-close', 'global.literals.finish', undefined, 'finishDiligence')
                        .setPermissions(['edit_input_entry_register', 'diligence_input_register', 'view_all_diligences']);
                    var readedDiligenceButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa fa-close', 'global.reg.diligenceState.READ', undefined, 'readDiligence');

                    if (AnnexaPermissionsFactory.haveProfile(diligence.diligenceProfile) && diligence.diligenceType.type == 'ACCEPTANCE') {
                        if (_.contains(['PENDING_ACCEPT', 'REJECT'], diligence.diligenceState) && diligence.registerEntryDiligenceHistorical && diligence.registerEntryDiligenceHistorical.regEntryDiligenceInit) {
                            diligenceActions.push(acceptDiligenceButton);
                        }
                        
                        if (_.contains(['PENDING_ACCEPT', 'ACCEPT', 'REJECT'], diligence.diligenceState) && diligence.registerEntryDiligenceHistorical && diligence.registerEntryDiligenceHistorical.regEntryDiligenceInit) {
                            diligenceActions.push(rejectDiligenceButton);
                        }

                        if ($rootScope.app.configuration.allow_diligence_send_to.value) {
	                        if ((!$rootScope.esetMode && _.contains(['PENDING_ACCEPT', 'ACCEPT', 'REJECT'], diligence.diligenceState)) || ($rootScope.esetMode && diligence.diligenceState == 'REJECT' && diligence.registerEntryDiligenceHistorical.regEntryDiligenceInit)) {
	                            diligenceActions.push(sendToDiligenceButton);
	                        }
                        }

                        if (diligence.diligenceState == 'ACCEPT') {
                            diligenceActions.push(finishDiligenceButton);
                        }
                    } else if (AnnexaPermissionsFactory.haveProfile(diligence.diligenceProfile) && diligence.diligenceType.type == 'ACCESS') {
                        if(diligence.diligenceState == 'PENDING_RECEPT') {
                            diligenceActions.push(readedDiligenceButton);
                        }
                    }

                    if (diligenceActions.length > 0) {
                        $rootScope.subHeadButtons.push(
                            new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-registerentry', undefined, 'global.literals.diligence', undefined,
                                'initDiligence', undefined, undefined, undefined, diligenceActions, 'dropdown-menu primary pull-right').setPermissions(['edit_input_entry_register', 'diligence_input_register', 'view_all_diligences'])
                        );
                    }

                    //endregion

                    //region Dossier Buttons

                    var dossierActions = [];

                    var initDossierButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa-plus fa-fw', 'global.reg.edit.sortida.initExp', undefined, 'initDossier')
                        .setPermissions(['start_dossier']);
                    var attachDossierButton = new HeadButton('btn primary breadDivButton', undefined, '#edit-registerentry', 'fa-paperclip fa-fw', 'global.reg.literals.attachExp', undefined, 'attachDossier')
                        .setPermissions(['process_dossier']);

                    if (diligence.diligenceState == 'ACCEPT' && AnnexaPermissionsFactory.haveProfile(diligence.diligenceProfile)) {
                        if (!diligence.dossier && diligence.diligenceType.canStartDossier) {
                            dossierActions.push(initDossierButton);
                        }

                        if (diligence.diligenceType.canAppendDossier) {
                            dossierActions.push(attachDossierButton);
                        }
                    }

                    if (dossierActions.length > 0) {
                        $rootScope.subHeadButtons.push(
                            new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-registerentry', undefined, 'global.literals.dossier', undefined, 'initDossier', undefined,
                                undefined, undefined, dossierActions, 'dropdown-menu primary pull-right').setPermissions(['start_dossier', 'process_dossier'])
                        );
                    }

                    //endregion

                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-registerentry', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDiligence').setPermissions('admin_audit'));
                }
            });

            HeaderService.changeState($state.current);
        }

        updateHeaderButtons();

        $scope.$on('registerEntryDiligenceStateUpdated', function (event, args) {
            updateHeaderButtons();
        })

        // endregion
    }]);
