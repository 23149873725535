/**
/**
 * Created by osirvent on 06/09/2016.
 */
angular
    .module('annexaApp')
    .factory('RegFactory',['$q', '$http','AnnexaFormlyFactory','$rootScope', 'TableFilter', 'DTColumnBuilder', '$filter', 'RegModals', 'RestService', 'HelperService', 'Language', 'ErrorFactory', 'DccumentsFactory', '$sce', 'AnnexaModalFactory', 'AnnexaPermissionsFactory', 'SignLocalFactory', 'CommonService', 'GlobalDataFactory', '$state', '$timeout', 'DialogsFactory', function($q, $http, AnnexaFormlyFactory, $rootScope, TableFilter, DTColumnBuilder, $filter, RegModals, RestService, HelperService, Language, ErrorFactory, DccumentsFactory, $sce, AnnexaModalFactory, AnnexaPermissionsFactory, SignLocalFactory, CommonService, GlobalDataFactory, $state, $timeout, DialogsFactory) {
        var factory = {};

        //region Globals

        factory.registerEntryDiligenceTypes = [];
        factory.regEntry = {};
        factory.defaultRegisterEntryOffice = undefined;
        factory.regEntryTypes = [
            { id: 'INPUT', language1: $filter('translate')('global.literals.input'), language2: $filter('translate')('global.literals.input'), language3: $filter('translate')('global.literals.input') },
            { id: 'OUTPUT', language1: $filter('translate')('global.literals.output'), language2: $filter('translate')('global.literals.output'), language3: $filter('translate')('global.literals.output') }
        ]

        //endregion

        //region Router

        factory.getRegisterEntryLists = function() {

            var promises = [];

            if(factory.registerEntryDiligenceTypes.length == 0) {
                promises.push(RestService.findAll('RegisterDiligenceType', undefined, undefined, { router: 'registerEntryDiligenceTypes' }));
            }

            return $q.all(promises)
                .then(function(data) {
                    if(factory.registerEntryDiligenceTypes.length == 0) {
                        factory.registerEntryDiligenceTypes = CommonService.getRouterPromise(data, 'registerEntryDiligenceTypes');
                    }

                    if($rootScope.app.configuration.multiple_register_office) {
                        if(!$rootScope.app.configuration.multiple_register_office.active) {
                            var idDefaultRegisterOffice = $rootScope.app.configuration.multiple_register_office.default_office ? $rootScope.app.configuration.multiple_register_office.default_office : -1;
                            factory.defaultRegisterEntryOffice = $linq(GlobalDataFactory.registerEntryOffices).singleOrDefault(undefined, "x => x.id == " + idDefaultRegisterOffice);
                        } else {
                            if(GlobalDataFactory.registerEntryOffices.length == 1) {
                                factory.defaultRegisterEntryOffice = GlobalDataFactory.registerEntryOffices[0];
                            }
                        }
                    }
                }).catch(function(error) {
                    factory.registerEntryDiligenceTypes = [];
                    factory.defaultRegisterEntryOffice = undefined;

                    console.error(error);
                })
        }

        factory.getReqisterEntryUpdateAccessDiligences = function(id) {
            return $http({
                url: './api/reg/' + id + '/update_access_diligences',
                method: 'GET'
            }).then(function (data) {
                factory.regEntry = JSOG.decode(data.data);
            }).catch(function (error) {
                console.error(error);
            });
        }

        factory.getRegisterEntry = function(id) {
            return $http({
                url: './api/reg/' + id,
                method: 'GET'
            }).then(function (data) {
                factory.regEntry = JSOG.decode(data.data);
            }).catch(function (error) {
                console.error(error);
            });
        }

        //endregion

        //region RegisterEntry

        factory.createRegisterEntry = function(registerEntry) {
            var deferred = $q.defer();

            $http({
                url: './api/reg',
                method: 'POST',
                data: JSOG.encode(registerEntry)
            }).then(function(data) {
                if(data.status == 200) { 
                	if(data && data.headers && data.headers('warningMessage')){
                		DialogsFactory.notify('DIALOGS_COMPLETE_GENERATE_STAMP_KO');
                	}
                	deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.createSupportingDocument = function(id, entryType, returnType) {
            var deferred = $q.defer();

            var translations = {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "supportingDoc": entryType == 'INPUT' ? $filter('translate')('global.literals.supportingDoc') : $filter('translate')('global.literals.evidenceDoc'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no'),
                "languageColumn": Language.getActiveColumn()
            }

            var httpConfig = {
                url: './api/reg/' + id + '/supporting_document',
                method: 'POST',
                data: translations,
                cache: false
            }

            if(returnType == 'FILE') {
                httpConfig.responseType = 'arraybuffer';
            }

            if(returnType == 'DOCUMENT') {
                httpConfig.url += '/document'
            }

            $http(httpConfig).then(function(response) {
                if(returnType == 'FILE') {
                    var file = new Blob([response.data], {type: 'application/pdf'});

                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        deferred.resolve(file);
                    } else {
                        var linkElement = document.createElement('a');
                        try {
                            var url = window.URL.createObjectURL(file);
                            linkElement.setAttribute('href', url);
                            linkElement.setAttribute('target', '_blank');
                            deferred.resolve(linkElement);
                        } catch (e) {
                            console.error(e.message);
                        }
                    }
                } else if(returnType == 'DOCUMENT') {
                    deferred.resolve(JSOG.decode(response.data));
                }
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.generateDocumentSicresFile = function(id, params) {
        	var deferred = $q.defer();
        	
            var fileContentsForDownload = function(headers, data) {
                var retDownload = {
                    file: undefined,
                    fileName: undefined,
                    linkElement: undefined,
                    clickEvent: undefined
                }

                var contentType = headers['content-type'];
                retDownload.file = new Blob([data], { type: contentType });
                retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');

                try {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                    } else {
                        retDownload.linkElement = document.createElement('a');
                        var url = window.URL.createObjectURL(retDownload.file);
                        retDownload.linkElement.setAttribute('href', url);
                        retDownload.linkElement.setAttribute("download", retDownload.fileName);
                        retDownload.clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                    }
                } catch(e) {
                }
            }
        	
            $http({
                url: './api/reg/' + id + '/generateDocumentSicres',
                method: 'POST',
                responseType: 'arraybuffer',
                data: JSOG.encode(params)
            }).then(function (data) {                
            	if(data.status == 200) {
                   fileContentsForDownload(data.headers(), data.data);
                   deferred.resolve(true);
                } else {
                	deferred.reject(error);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.generateDocumentSicres = function(id, params) {
        	var deferred = $q.defer();
        	
            $http({
                url: './api/reg/' + id + '/generateDocumentSicres/document',
                method: 'POST',
                data: JSOG.encode(params)
            }).then(function (data) {                
            	deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getFieldsSicresForm = function(id, params) {
        	var deferred = $q.defer();
        	
        	$http({
                url: './api/reg/getFieldsSicresForm',
                method: 'GET'
            }).then(function (data) {                
            	deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })
        	
        	return deferred.promise;
        }

        factory.getPermissionsUserEntryType = function(entryType, edit) {
            var editDays = 0;
            var permissions = [];

            var getPermissions = function (type) {
                var perms = [];

                switch (type) {
                    case 'INPUT':
                        if (AnnexaPermissionsFactory.havePermission('edit_input_entry_register')) {
                            perms.push('edit_input_entry_register');
                        }
                        if (AnnexaPermissionsFactory.havePermission('new_input_entry_register')) {
                            perms.push('new_input_entry_register');
                        }
                        break;
                    case 'OUTPUT':
                        if (AnnexaPermissionsFactory.havePermission('edit_output_entry_register')) {
                            perms.push('edit_output_entry_register');
                        }
                        if (AnnexaPermissionsFactory.havePermission('new_output_entry_register')) {
                            perms.push('new_output_entry_register');
                        }
                        break;
                }

                return perms;
            }

            if(edit && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.reg_edit_days && $rootScope.app.configuration.reg_edit_days.value) {
                editDays = $rootScope.app.configuration.reg_edit_days.value;
            }

            if(editDays == 0 || (editDays > 0 && HelperService.diffDateDays(factory.regEntry.createdDate, new Date() <= editDays))) {
                permissions = getPermissions(entryType);
            }

            return permissions;
        };

        factory.newRegisterEntryThird = function(id, newRegisterEntryThird) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds',
                method: 'POST',
                data: JSOG.encode(newRegisterEntryThird)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.updateRegisterEntryThird = function(id, registerEntryThird) {
            var deferred = $q.defer();

            $http({
                url: './api/register_entry_third/',
                method: 'PUT',
                data: JSOG.encode(registerEntryThird)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.deleteRegisterEntryThird = function(id, removedIds) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds/' + CommonService.getParameterList(removedIds),
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.setPrincipalRegisterEntryThird = function(id, thirdId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds/set_principal/' + thirdId,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.getOptionsRoleInterested = function() {
        	var promises = [];
        	promises.push(RestService.findAll('RoleInterested'));
        	return $q.all(promises).then(function(data) {
        		factory.optionsRoleInterested = JSOG.decode(data[0].data);
        	}).catch(function(error) {
            })
        }

        factory.newRegisterEntryDocument = function(id, newRegisterEntryDocuments) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/documents',
                method: 'POST',
                data: JSOG.encode(newRegisterEntryDocuments)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDocument = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/documents/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryDossier = function(id, dossierId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/dossiers/' + dossierId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDossier = function(id, dossierId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/dossiers/' + dossierId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDiligence = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/diligences/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryDiligence = function(id, diligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/diligences',
                method: 'POST',
                data: JSOG.encode(diligence)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryRelatedRegisterEntry = function(id, registerEntryId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/related_register_entries/' + registerEntryId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryRelatedRegisterEntry = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/related_register_entries/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryTask = function(id, taskId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/tasks/' + taskId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryTask = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/tasks/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion

        // region RegisterEntryDiligence

        factory.newDiligenceModal = function(content, submit, title, withoutDiligenceType, defaults, profilesRegisterOffice) {
            var getDiligenceTypes = function () {
                var diligenceTypes = factory.registerEntryDiligenceTypes;

                if($linq(content).count("x => x.active && x.diligenceType.canStartDossier") > 0 && !$rootScope.app.configuration.register_init_dossier_diligence_type_multiple.value) {
                    diligenceTypes = $linq(factory.registerEntryDiligenceTypes).where("x => !x.canStartDossier").toArray();
                }

                return diligenceTypes;
            }
           
            // formulario de crear nueva diligencia
            var newModal = {
                title: title ? title : 'global.reg.modal.new.dilig',
                size: '',
                fieldsDefinition: [
                    { type: 'field', id: 'diligenceType', fieldType: 'select', data: getDiligenceTypes(), required: true, label: 'global.literals.type', remove: withoutDiligenceType, model: defaults && defaults.diligenceType ? defaults.diligenceType.id : undefined },
                    { type: 'field', id: 'diligenceProfiles', fieldType: 'component', componentType: 'annexa-typeahead-fieldset-profiles',
                        data: {
                            label: 'global.literals.profiles',
                            placeholder: 'global.reg.literals.profileOrUserName',
                            onlyOneProfile: withoutDiligenceType,
                            rowClass: 'm-b-xs row',
                            labelStrong: true,
                            profilesOk: profilesRegisterOffice
                        },
                        controller: ['$scope', function($scope) {
                            $scope.options.data.contents = $scope.model.diligenceProfiles;
                        }],
                        defaultValue: []
                    },
                    { type: 'field', id: 'comment', fieldType: 'textarea', required: false, label: 'global.literals.comments' }
                ],
                alerts: [],
                submitModal: submit
            }

            return newModal;
        }

        factory.responsibleUser = function(registerEntryDiligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/responsible_user',
                method: 'PUT',
                data: registerEntryDiligence.responsibleUser ? undefined : { id: $rootScope.LoggedUser.id }
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve('OK');
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateRegisterEntryDiligenceState = function(registerEntryDiligence, diligenceState, comment) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/state/' + diligenceState,
                method: 'PUT',
                data: comment
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(data);
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateRegisterEntryDiligenceActive = function(registerEntryDiligence, active) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/active',
                method: 'PUT',
                data: active
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(data);
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.sendToDiligence = function(registerEntryDiligence, newRegisterEntryDiligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/send_to',
                method: 'PUT',
                data: JSOG.encode(newRegisterEntryDiligence)
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.sendTodiligenceModalOpen = function(previousDiligence, registerEntry, redirectTo) {
            var submitSendTo = function() {
                this.form.$setSubmitted();

                if(!this.model.diligenceProfiles || this.model.diligenceProfiles.length == 0) {
                    sendToDiligenceModal.alerts.push({ msg: 'global.errors.profiles_required'});
                }

                if(this.form.$valid && this.model.diligenceProfiles && this.model.diligenceProfiles.length != 0) {
                    var newDiligence = {
                        diligenceType: previousDiligence.diligenceType,
                        diligenceProfile: this.model.diligenceProfiles[0].profile ? { id: this.model.diligenceProfiles[0].profile.id } : undefined,
                        responsibleUser: this.model.diligenceProfiles[0].responsible ? { id: this.model.diligenceProfiles[0].responsible.id } : undefined,
                        registerEntryDiligenceComments: this.model.comment ? [{ comments: this.model.comment }] : [],
                        registerEntry: { id: registerEntry.id, profile: { id: registerEntry.profile.id } }
                    }

                    factory.sendToDiligence(previousDiligence, newDiligence)
                        .then(function(data) {
                            sendToDiligenceModal.close();

                            if(redirectTo) {
                                $state.go(redirectTo);
                            } else {
                                $rootScope.$broadcast('registerEntryDiligenceSendedTo', { previousDiligence: previousDiligence, newDiligence: data });
                            }
                        }).catch(function(error) {
                            console.error(error);
                            sendToDiligenceModal.alerts.push({ msg: ErrorFactory.getErrorMessage('registerDiligence', 'sendTo', error) });
                    });
                }
            }
            
            var profilesRegisterOffice = [];
            if(registerEntry && registerEntry.registerEntryOffice && registerEntry.registerEntryOffice.profiles){
            	profilesRegisterOffice = $linq(registerEntry.registerEntryOffice.profiles).select("x => x.profile").toArray();
            }

            var sendToDiligenceModal = factory.newDiligenceModal([], submitSendTo, 'global.reg.modal.sendDiligenceTo', true, { diligenceType: previousDiligence.diligenceType }, profilesRegisterOffice);
            AnnexaFormlyFactory.showAnnexaFormModal('modalSendTo', sendToDiligenceModal);
        }

        factory.attachToDossier = function (registerEntryDiligence,dossierTransactionId, receiverIds, documentIds) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/attach_dossier_transaction/' + dossierTransactionId + '/' + CommonService.getParameterList(receiverIds) + '/' + CommonService.getParameterList(documentIds),
                method: 'PUT'
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // endregion

        //region RegisterEntryDiligenceComment

        factory.createRegisterEntryDiligenceCommentModal = function(diligence) {
            var newModal = {
                title: 'global.literals.newComment',
                size: '',
                fieldsDefinition: [
                    { type: 'field', id: 'registerEntryDiligenceComments', fieldType: 'component', componentType: 'annexa-register-entry-diligence-comments',
                        data: {
                            registerEntryDiligence: diligence,
                            addComments: true
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                    this.close();
                },
                hideSubmit: true,
                closeLabel: 'global.literals.close'
            }

            AnnexaFormlyFactory.showAnnexaFormModal('modalNewRegisterEntryDiligenceComment', newModal);
        };

        factory.newRegisterEntryDiligenceComment = function(comment) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence_comment',
                method: 'POST',
                data: JSOG.encode(comment)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion

        //region Modal

        factory.newRegisterEntryModal = function(entryType, thirds, documents, dossiers, submit, fromDossier) {
            var newModal = {
                id: 'modalNewRegisterEntry',
                title: 'global.literals.new_register_modal_title',
                size: 'modal-lg',
                icon: '',
                isEdit: false,
                isModal: true,
                redirectToList: false,
                entryType: entryType,
                preloadBoxes: { thirds: thirds, documents: documents, dossiers: dossiers },
                fromDossier: fromDossier,
                alerts: [],
                submitModal: function () {
                    $timeout(function () {
                        angular.element('#annexaForm-new-registerentry').trigger('click');
                    });
                }
            }

            AnnexaFormlyFactory.showAnnexaFormModalObject('modalNewRegisterEntry', newModal);
        }

        //endregion

        //region RegisterEntryClassification

        factory.findFamiliesByRegisterClassification = function(registerClassification, profile) {
            var callUrl = './api/reg/register_entry_classification';

            if(registerClassification && registerClassification.id) {
                callUrl += '/' + registerClassification.id;
            }

            callUrl += '/families';

            if(profile && profile.id) {
                callUrl += '/' + profile.id;
            }

            var httpPromise = $http({
                url: callUrl,
                method: 'GET'
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        //endregion

        return factory;
    }]);